//@import "~bootstrap/scss/bootstrap.scss";

.course-icon {
    transition: 0.5s ease;
}

.course-icon:hover{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 0.5s ease;
}

.course-icon {
    width: 60px;
    height: 60px;
}

.course-container {
	position: relative;
	.course-priority {
		color: white;
		text-shadow: 2px 2px 4px #000000;
		border-radius: 50%;
		border-color: gainsboro;
		border-width: 1px;
		border-style: solid;
		padding: 0px 8px;
		position: absolute;
		left: 50%;
		transform: translate(10px);
	}
}

/*
@include media-breakpoint-up(md) { 
    .course-icon {
        width: 100px;
        height: 100px;
    }
}*/
